'use client';

import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { cn } from '../../utils/cn';
const TooltipProvider = TooltipPrimitive.Provider;
const Tooltip: React.FC<React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root>> = ({
  delayDuration = 50,
  ...props
}) => <TooltipPrimitive.Root delayDuration={delayDuration} {...props} data-sentry-element="unknown" data-sentry-component="Tooltip" data-sentry-source-file="tooltip.tsx" />;
Tooltip.displayName = TooltipPrimitive.Root.displayName;
const TooltipTrigger: React.FC<React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Trigger>> = ({
  className,
  ...props
}) => <TooltipPrimitive.Trigger className={className} {...props} data-sentry-element="unknown" data-sentry-component="TooltipTrigger" data-sentry-source-file="tooltip.tsx" />;
TooltipTrigger.displayName = TooltipPrimitive.Trigger.displayName;
const TooltipContent = React.forwardRef<React.ElementRef<typeof TooltipPrimitive.Content>, React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>>(({
  className,
  children,
  sideOffset = 4,
  ...props
}, ref) => <TooltipPrimitive.Content ref={ref} sideOffset={sideOffset} className={cn('z-50 overflow-hidden rounded-md bg-white border border-zinc-200 shadow-sm px-3 py-1.5 text-xs text-zinc-600 animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:bg-zinc-50 dark:text-zinc-900 select-none', className)} {...props}>
    {children}
  </TooltipPrimitive.Content>);
TooltipContent.displayName = TooltipPrimitive.Content.displayName;
export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };