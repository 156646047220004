/// <reference types="next/types/global" />

import fetchRetry, { RequestInitWithRetry } from 'fetch-retry';
import { getRequestUrl } from './util';

const fetchWithRetry = fetchRetry(fetch, {
  retryDelay: (attempt, error, response) => {
    // Retry with exponential backoff
    return Math.pow(2, attempt) * 3000;
  },
});

const RETRY_ERROR_CODES: number[] = [
  // Request Timeout
  408,
  // Too Many Requests
  429,
  // Bad Gateway
  502,
  // Service Unavailable
  503,
  // Gateway Timeout
  504,
];

export type FetchWithGuardRequestInit = Omit<RequestInitWithRetry, 'method' | 'body'> & {
  timeout?: number;
};

export function fetchWithGuard<T, TParams extends Record<string, any> = Record<string, any>>(
  path: string,
  method: RequestInit['method'] = 'GET',
  params?: TParams,
  init?: FetchWithGuardRequestInit & {
    transformRequest?: (value: TParams) => string;
  }
): Promise<T> {
  const { transformRequest = JSON.stringify } = init ?? {};
  const fetchOptions: RequestInitWithRetry = {
    ...init,
    method,
    body: method.toUpperCase() !== 'GET' ? transformRequest(params) : undefined,
  };

  return new Promise((resolve, reject) =>
    fetchWithTimeout(
      method.toUpperCase() === 'GET' ? getRequestUrl(path, params) : path,
      fetchOptions
    )
      .then(async (response) => {
        try {
          const data = await response.json();
          if (response.status >= 400) {
            let error: string = response.status + response.statusText;
            if ('error' in data) {
              error = data.error;
            }
            return reject(new Error(error));
          }
          resolve(data);
        } catch (e) {
          console.error('Failed to decode JSON: ', e);
          reject(e);
        }
      })
      .catch(reject)
  );
}

export function fetchWithTimeout(
  input: string | URL | Request,
  { timeout, ...init }: FetchWithGuardRequestInit = {}
): Promise<Response> {
  return fetchWithRetry(input, {
    ...init,
    signal: timeout ? AbortSignal.timeout(timeout) : null,
    retryOn: RETRY_ERROR_CODES,
  });
}
