'use client';

import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { IClientConfig } from './client-config';
type ClientConfigProviderProps = {
  appFocusHandler?: (handler: () => void) => (() => void) | undefined;
};
type ClientConfigContextType = IClientConfig & ClientConfigProviderProps;

// Use as the appFocusHandler to enable refetch on window focus.
// TODO: Add a mechanism to opt out of this behaviour per query.
export const windowFocusHandler = (handler: () => void) => {
  if (typeof window?.addEventListener !== 'undefined') {
    window.addEventListener('focus', handler);
    return () => {
      window.removeEventListener('focus', handler);
    };
  }
};
const ClientConfigContext = createContext<ClientConfigContextType>({} as ClientConfigContextType);
export const ClientConfigProvider: FC<PropsWithChildren<ClientConfigProviderProps & {
  value: IClientConfig;
}>> = ({
  children,
  value,
  appFocusHandler
}) => {
  const contextValue = useMemo(() => ({
    ...value,
    appFocusHandler
  }), [value, appFocusHandler]);
  return <ClientConfigContext.Provider value={contextValue} data-sentry-element="unknown" data-sentry-component="ClientConfigProvider" data-sentry-source-file="client-config-provider.tsx">{children}</ClientConfigContext.Provider>;
};
export const useClientConfig = (): ClientConfigContextType => useContext(ClientConfigContext);