import classNames from 'classnames';
import { HoleResult } from './types';
import { RoundAnalyticsPlayerTypeEnum, MatchplayMatchesMatchesInnerPlayersInner, ElasticCourseData, TournamentEventTypeEnum } from '@spikemark/rest-api';
import isNumber from 'lodash/isNumber';
import { LeaderboardDisplayMode } from '../types/leaderboard';
import { LiveScoringSystem } from '@spikemark/live';
export const displayTime = (input: Date): string => {
  const newHours = input.getHours() > 12 ? input.getHours() - 12 : input.getHours();
  return [newHours, input.getMinutes()].map(x => String(x).padStart(2, '0')).join(':');
};
export function convertToAmericanDateFormat(dateString: string): string {
  const [year, month, day] = dateString.split('-').map(Number);
  const date = new Date(year, month - 1, day);
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: 'UTC'
  }).format(date);
  return formattedDate;
}
export const formatRawDate = (date: Date, includeYear?: boolean): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: includeYear === false ? undefined : 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC'
  };
  const dateFormatter = new Intl.DateTimeFormat('en-US', options);
  return dateFormatter.format(date);
};
export const formatDate = (dateInput: string | Date, includeYear?: boolean): string => {
  if (dateInput instanceof Date) {
    dateInput = dateInput.toISOString();
  }
  const date = new Date(dateInput);
  return formatRawDate(date, includeYear);
};
export const formatTournamentDate = ({
  startDate,
  endDate
}: {
  startDate: string | Date;
  endDate: string | Date;
}) => {
  const formattedEndDate = formatDate(endDate);
  if (String(startDate) === String(endDate)) {
    return formattedEndDate;
  }
  const formattedStartDate = formatDate(startDate, false);
  return `${formattedStartDate} - ${formattedEndDate}`;
};
export const rankToDisplayValue = (rank?: number) => {
  if (typeof rank === 'undefined' || Number.isNaN(rank)) {
    return '-';
  }
  if (rank > 9000 || rank === 0) {
    return 'N/A';
  } else {
    return `#${rank}`;
  }
};
export const strokesToDisplayValue = (strokes: number) => {
  if (Number.isNaN(strokes) || !strokes) {
    return '-';
  }
  if (strokes > 9000) {
    return 'N/A';
  } else {
    return strokes;
  }
};
export const scoreToDisplayValue = (score: number) => {
  if (score === 0) {
    return ' E';
  } else if (score > 10000) {
    return 'N/A';
  } else if (score > 0) {
    return `+${score}`;
  } else {
    return String(score);
  }
};
const holeResultToDisplayValue = (result: HoleResult, mode?: LeaderboardDisplayMode) => {
  const {
    par,
    strokes
  } = result;
  const score = (par - strokes) * -1;
  let displayValue = '';
  if (mode === 'score') {
    displayValue = scoreToDisplayValue(score);
  } else if (mode === 'stroke') {
    displayValue = String(strokesToDisplayValue(strokes));
  }
  return displayValue;
};
export const displayScoreResult = (result: HoleResult, mode?: LeaderboardDisplayMode) => {
  const {
    par,
    strokes
  } = result;
  const score = (par - strokes) * -1;
  const displayValue = holeResultToDisplayValue(result, mode);
  if (score > 0) {
    return <span className="text-bogey-blue font-semibold">{displayValue}</span>;
  } else if (score < 0) {
    return <span className="text-birdie-red font-semibold">{displayValue}</span>;
  } else {
    return <span className="font-semibold">{displayValue}</span>;
  }
};
export const displayHoleResult = (result: HoleResult, mode?: LeaderboardDisplayMode) => {
  const {
    par,
    strokes
  } = result;
  const score = (par - strokes) * -1;
  const displayValue = holeResultToDisplayValue(result, mode);
  return score === 0 || strokes === 0 ? <span data-sentry-component="displayHoleResult" data-sentry-source-file="format.tsx">{displayValue}</span> : <div className={classNames('inline-block', {
    border: score >= 2 || score <= -2,
    'p-0.5 -my-0.5 rounded-lg border-over-par-gray': score >= 2,
    'p-1 -my-1 rounded-full border border-eagle-yellow': score <= -2
  })} data-sentry-component="displayHoleResult" data-sentry-source-file="format.tsx">
      <div className={classNames('text-center inline-flex justify-center text-white w-6 h-6 lg:w-7 lg:h-7 whitespace-nowrap p-1', {
      'rounded-md': score > 0,
      'rounded-full w-7 h-7 lg:w-8 lg:h-8 p-1.5 -m-0.5': score < 0,
      'bg-over-par-gray': score >= 2,
      'bg-bogey-blue': score === 1,
      'bg-birdie-red': score === -1,
      'bg-eagle-yellow': score <= -2
    })}>
        <div className="self-center">{displayValue}</div>
      </div>
    </div>;
};
export const displayUsername = (name?: string, playertype?: RoundAnalyticsPlayerTypeEnum) => {
  if (playertype === RoundAnalyticsPlayerTypeEnum.Individual) {
    return `${name} (Ind)`;
  } else {
    return name;
  }
};
export const displayTeamName = (schoolName: string, teamLabel?: string) => {
  return `${schoolName.replace(/[\[\(][A-Z][\]\)]/, '')}${teamLabel && teamLabel !== 'A' ? ` (${teamLabel})` : ''}`;
};
export const displayCourseDetail = (course: ElasticCourseData): string => `${course.courseName} (${course.totalYards.toLocaleString()} yards) | Par ${course.totalPar}`;

//We're not recieving holes played in most places currently, as live scoring doesn't exist.
//However we will need to start mapping this properly as soon as we have the data
export const formatThru = (holesPlayed: number | string) => {
  if (holesPlayed == 0) {
    return '-';
  } else if (holesPlayed == 18) {
    return 'F';
  } else {
    return String(holesPlayed);
  }
};
export const formatRankingPoints = (points?: number) => {
  return isNumber(points) && points >= 0 ? points.toFixed(2) : '-';
};
export const formatFloatValue = (value?: number, fractionDigits?: number) => {
  return typeof value === 'number' ? value.toFixed(fractionDigits ?? 2) : '-';
};
export const formatStrengthOfField = (points?: number) => {
  return typeof points === 'number' ? points.toFixed(2) : '-';
};
export const formatPlayerScore = (result: Pick<MatchplayMatchesMatchesInnerPlayersInner, 'holesLeft' | 'playerDetailUp' | 'playerResult'>, format?: TournamentEventTypeEnum) => {
  if (result.playerDetailUp > 1000) {
    return 'Win';
  }
  if (result.playerDetailUp < -1000) {
    return 'W/D';
  }
  if (result.playerDetailUp === 0) {
    return 'Tied';
  }
  const isMatchComplete = result.holesLeft > 0 && Math.abs(result.playerDetailUp) > result.holesLeft;
  if (result.holesLeft === 0 || !isMatchComplete || format === 'Medal Match Play') {
    if (result.playerDetailUp > 0) {
      return `${result.playerDetailUp}Up`;
    } else {
      return `${Math.abs(result.playerDetailUp)}Dn`;
    }
  } else if (result.holesLeft > 18) {
    return `${result.holesLeft} Holes`;
  } else {
    return `${Math.abs(result.playerDetailUp)}&${result.holesLeft}`;
  }
};
const shortDivisionLabels: Record<string, string> = {
  'NCAA Division I': 'DI',
  'NCAA Division II': 'DII',
  'NCAA Division III': 'DIII'
};
export const formatShortDivisionName = (division: string): string => {
  return shortDivisionLabels[division] ?? division;
};
export function isNonZero<T>(value: number | undefined, defaultValue: T): number | T {
  return value && value >= 0 ? value : defaultValue;
}

/**
 * Format a number as an ordinal, e.g. `3` to `3rd`
 * link https://stackoverflow.com/a/57518703/223225
 * @param {number} number To format
 */
const english_ordinal_rules = new Intl.PluralRules('en', {
  type: 'ordinal'
});
export function formatNumberOrdinal(number: number) {
  const category = english_ordinal_rules.select(number);
  switch (category) {
    case 'one':
      {
        return `${number}st`;
      }
    case 'two':
      {
        return `${number}nd`;
      }
    case 'few':
      {
        return `${number}rd`;
      }
    default:
      {
        return `${number}th`;
      }
  }
}
export function formatSignedNumber(number: number = 0): string {
  return number > 0 ? `+${number}` : `${number}`;
}
const scoringSystemValueMap: Record<LiveScoringSystem, string> = {
  scorecard_swap: 'Paperless Official Scoring',
  one_marks_all: 'Player Walking Scoring',
  volunteer_group: 'Volunteer Walking Scoring',
  volunteer_greenside: 'Greenside Scoring'
};
export function formatScoringSystem(val?: LiveScoringSystem) {
  return val ? scoringSystemValueMap[val] : '';
}