/* tslint:disable */
/* eslint-disable */
/**
 * Clippd Spikemark API
 * # Overview Spikemark provides tournament scoring, real-time leader boards, detailed statistics, in-depth analytics and media through a platform that  includes easy-to-navigate screens allowing users to keep track of their favorite teams and players.  Clippd is an AI-focussed technology business building a connected future for golfers and coaches who have a passion for performance.  At our core is a universal platform that aggregates and elevates all the performance data created by millions of golfers and coaches every day.   This documentation provides the \"Spikemark API\", powered by the Clippd platform. The purpose of the API is to provide advanced analytics and  integration for rankings for collegiate golf.  In addition to this documentation, we also provide an [OpenAPI](https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.1.md)  specification describing the Clippd API Specification in [YAML](openapi.yaml) and [JSON](openapi.json) formats.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@clippd.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RoundAnalyticsRoundsInner } from './RoundAnalyticsRoundsInner';
import {
  RoundAnalyticsRoundsInnerFromJSON,
  RoundAnalyticsRoundsInnerFromJSONTyped,
  RoundAnalyticsRoundsInnerToJSON,
} from './RoundAnalyticsRoundsInner';

/**
 * Object representing the result of an individual or a school
 * @export
 * @interface RoundAnalytics
 */
export interface RoundAnalytics {
  /**
   *
   * @type {string}
   * @memberof RoundAnalytics
   */
  schoolId: string;
  /**
   *
   * @type {string}
   * @memberof RoundAnalytics
   */
  schoolName: string;
  /**
   *
   * @type {string}
   * @memberof RoundAnalytics
   */
  teamLabel?: string;
  /**
   *
   * @type {string}
   * @memberof RoundAnalytics
   */
  schoolLogo?: string;
  /**
   *
   * @type {string}
   * @memberof RoundAnalytics
   */
  division?: string;
  /**
   *
   * @type {string}
   * @memberof RoundAnalytics
   */
  schoolLogoThumbnail?: string;
  /**
   *
   * @type {string}
   * @memberof RoundAnalytics
   */
  playerId?: string;
  /**
   *
   * @type {string}
   * @memberof RoundAnalytics
   */
  playerName?: string;
  /**
   *
   * @type {string}
   * @memberof RoundAnalytics
   */
  playerType?: RoundAnalyticsPlayerTypeEnum;
  /**
   *
   * @type {string}
   * @memberof RoundAnalytics
   */
  userPicture?: string;
  /**
   *
   * @type {number}
   * @memberof RoundAnalytics
   */
  roundId?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof RoundAnalytics
   */
  courseLabels?: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof RoundAnalytics
   */
  scores: Array<number>;
  /**
   *
   * @type {number}
   * @memberof RoundAnalytics
   */
  totalScore: number;
  /**
   *
   * @type {Array<number>}
   * @memberof RoundAnalytics
   */
  strokes: Array<number>;
  /**
   *
   * @type {number}
   * @memberof RoundAnalytics
   */
  totalStrokes: number;
  /**
   *
   * @type {Array<number>}
   * @memberof RoundAnalytics
   */
  totalPar?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof RoundAnalytics
   */
  totalYardage?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof RoundAnalytics
   */
  currentRound?: number;
  /**
   *
   * @type {number}
   * @memberof RoundAnalytics
   */
  holeThrough?: number;
  /**
   *
   * @type {number}
   * @memberof RoundAnalytics
   */
  currentRank?: number;
  /**
   *
   * @type {number}
   * @memberof RoundAnalytics
   */
  lastRank?: number;
  /**
   *
   * @type {number}
   * @memberof RoundAnalytics
   */
  overallTeamRank?: number;
  /**
   *
   * @type {number}
   * @memberof RoundAnalytics
   */
  overallPlayerRank?: number;
  /**
   *
   * @type {number}
   * @memberof RoundAnalytics
   */
  rankDelta?: number;
  /**
   *
   * @type {Array<RoundAnalyticsRoundsInner>}
   * @memberof RoundAnalytics
   */
  rounds?: Array<RoundAnalyticsRoundsInner>;
}

/**
 * @export
 */
export const RoundAnalyticsPlayerTypeEnum = {
  Team: 'team',
  Individual: 'individual',
} as const;
export type RoundAnalyticsPlayerTypeEnum =
  (typeof RoundAnalyticsPlayerTypeEnum)[keyof typeof RoundAnalyticsPlayerTypeEnum];

/**
 * Check if a given object implements the RoundAnalytics interface.
 */
export function instanceOfRoundAnalytics(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'schoolId' in value;
  isInstance = isInstance && 'schoolName' in value;
  isInstance = isInstance && 'scores' in value;
  isInstance = isInstance && 'totalScore' in value;
  isInstance = isInstance && 'strokes' in value;
  isInstance = isInstance && 'totalStrokes' in value;

  return isInstance;
}

export function RoundAnalyticsFromJSON(json: any): RoundAnalytics {
  return RoundAnalyticsFromJSONTyped(json, false);
}

export function RoundAnalyticsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RoundAnalytics {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    schoolId: json['schoolId'],
    schoolName: json['schoolName'],
    teamLabel: !exists(json, 'teamLabel') ? undefined : json['teamLabel'],
    schoolLogo: !exists(json, 'schoolLogo') ? undefined : json['schoolLogo'],
    division: !exists(json, 'division') ? undefined : json['division'],
    schoolLogoThumbnail: !exists(json, 'schoolLogoThumbnail')
      ? undefined
      : json['schoolLogoThumbnail'],
    playerId: !exists(json, 'playerId') ? undefined : json['playerId'],
    playerName: !exists(json, 'playerName') ? undefined : json['playerName'],
    playerType: !exists(json, 'playerType') ? undefined : json['playerType'],
    userPicture: !exists(json, 'userPicture') ? undefined : json['userPicture'],
    roundId: !exists(json, 'roundId') ? undefined : json['roundId'],
    courseLabels: !exists(json, 'courseLabels') ? undefined : json['courseLabels'],
    scores: json['scores'],
    totalScore: json['totalScore'],
    strokes: json['strokes'],
    totalStrokes: json['totalStrokes'],
    totalPar: !exists(json, 'totalPar') ? undefined : json['totalPar'],
    totalYardage: !exists(json, 'totalYardage') ? undefined : json['totalYardage'],
    currentRound: !exists(json, 'currentRound') ? undefined : json['currentRound'],
    holeThrough: !exists(json, 'holeThrough') ? undefined : json['holeThrough'],
    currentRank: !exists(json, 'currentRank') ? undefined : json['currentRank'],
    lastRank: !exists(json, 'lastRank') ? undefined : json['lastRank'],
    overallTeamRank: !exists(json, 'overallTeamRank') ? undefined : json['overallTeamRank'],
    overallPlayerRank: !exists(json, 'overallPlayerRank') ? undefined : json['overallPlayerRank'],
    rankDelta: !exists(json, 'rankDelta') ? undefined : json['rankDelta'],
    rounds: !exists(json, 'rounds')
      ? undefined
      : (json['rounds'] as Array<any>).map(RoundAnalyticsRoundsInnerFromJSON),
  };
}

export function RoundAnalyticsToJSON(value?: RoundAnalytics | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    schoolId: value.schoolId,
    schoolName: value.schoolName,
    teamLabel: value.teamLabel,
    schoolLogo: value.schoolLogo,
    division: value.division,
    schoolLogoThumbnail: value.schoolLogoThumbnail,
    playerId: value.playerId,
    playerName: value.playerName,
    playerType: value.playerType,
    userPicture: value.userPicture,
    roundId: value.roundId,
    courseLabels: value.courseLabels,
    scores: value.scores,
    totalScore: value.totalScore,
    strokes: value.strokes,
    totalStrokes: value.totalStrokes,
    totalPar: value.totalPar,
    totalYardage: value.totalYardage,
    currentRound: value.currentRound,
    holeThrough: value.holeThrough,
    currentRank: value.currentRank,
    lastRank: value.lastRank,
    overallTeamRank: value.overallTeamRank,
    overallPlayerRank: value.overallPlayerRank,
    rankDelta: value.rankDelta,
    rounds:
      value.rounds === undefined
        ? undefined
        : (value.rounds as Array<any>).map(RoundAnalyticsRoundsInnerToJSON),
  };
}
