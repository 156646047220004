'use client';

import { FC, PropsWithChildren, createContext, useContext, useEffect, useRef } from 'react';
type Cache = Map<string, any>;
const ClientCacheContext = createContext<Cache | undefined>(undefined);

// Very basic and naïve cache for useRemote; this currently only keeps the existing data
// to show cached data on mount whilst data fetching.
function useCache(): Cache {
  const cache = useRef<Cache>(new Map<string, any>());
  useEffect(() => {
    return () => {
      cache.current?.clear();
    };
  }, []);
  return cache.current;
}
export const ClientCacheProvider: FC<PropsWithChildren> = ({
  children
}) => <ClientCacheContext.Provider value={useCache()} data-sentry-element="unknown" data-sentry-component="ClientCacheProvider" data-sentry-source-file="client-cache-provider.tsx">{children}</ClientCacheContext.Provider>;
export const useClientCache = (): Cache | undefined => useContext(ClientCacheContext);